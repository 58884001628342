import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from '@api';
import { getCartId } from '@utils';
import { SERVER_ERROR } from '@config/constants';
import { GET_CART_QUERY, GetCartResponse } from '@graphql/guest';

export const getCart = createAsyncThunk<GetCartResponse>(
  'guest/getCart',
  async (_, { rejectWithValue }) => {
    const uid = getCartId();
    if (!uid) return rejectWithValue('Missed cart ID');

    try {
      const cartData = await client.request<GetCartResponse>(GET_CART_QUERY, { uid });
      return cartData;
    } catch (error) {
      return rejectWithValue(SERVER_ERROR);
    }
  },
);
