import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { regPass, regEmail } from './regexp';

export const LoginValidationSchema = () => {
  const { t } = useTranslation('', {
    keyPrefix: 'login.form.validation.errors',
  });

  return yup.object({
    email: yup
      .string()
      .required(t('emailRequire'))
      .matches(regEmail, t('emailFormat')),
    password: yup
      .string()
      .required(t('passwordRequire')),
    captchaToken: yup.string().required(t('captchaRequire')),
  });
};

export const RegistrationValidationSchema = () => {
  const { t } = useTranslation('', {
    keyPrefix: 'registration.form.validation.errors',
  });

  return yup.object({
    email: yup
      .string()
      .required(t('emailRequire'))
      .matches(regEmail, t('emailFormat')),
    confirmEmail: yup
      .string()
      .required(t('confirmEmailRequire'))
      .matches(regEmail, t('emailFormat'))
      .oneOf([yup.ref('email')], t('confirmEmailMatch')),
    password: yup
      .string()
      .required(t('passwordRequire'))
      .min(10, t('passwordLength'))
      .matches(regPass, t('passwordFormat')),
    captchaToken: yup.string().required(t('captchaRequire')),
  });
};
