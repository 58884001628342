import { cn } from '@utils';
import './style.scss';

type CommonButtonProps = {
  type?: 'primary' | 'secondary' | 'tertiary';
  className?: string;
  onClick?: () => void;
  icon?: React.ReactNode;
  size?: 'small' | 'regular';
  children?: React.ReactNode;
  loading?: boolean;
  disabled?: boolean;
};

export const CommonButton = (props: CommonButtonProps) => {
  return (
    <button
      data-testid="common-button"
      onClick={props.onClick}
      className={cn(
        'common-button',
        [props.type, props.className, props.size],
        props.loading ? 'common-button--loading' : '',
      )}
      type="button"
      disabled={props.disabled}
    >
      {props.icon && (
        <span className="d-flex" data-testid="common-button__icon">
          {props.icon}
        </span>
      )}
      <span className="common-button__title">
        {props.children}
      </span>
    </button>
  );
};

CommonButton.defaultProps = {
  className: '',
  onClick: () => { },
  icon: null,
  size: 'regular',
  type: 'primary',
};
