import { useAEMResource } from "@hooks";

const Footer = () => {
  const { footer, stylesLoaded } = useAEMResource();

  if (!stylesLoaded || !footer) return null;
  return <footer dangerouslySetInnerHTML={{ __html: footer }} />;
}

export { Footer };
