/* eslint-disable no-underscore-dangle */

import {
  AGS_HOST_URL,
  DEFAULT_LOCALE,
  VALID_LOCALES,
  VET_HOST_URL,
  WILEYCOM_HOST_URL,
} from '@config/constants';
import { logWarning } from './log';
import { getSearchParamFromUrl } from './url';

export enum SiteId {
  WILEYCOM = 'wileyb2c',
  COD = 'subscription',
  VET = 'vet',
  AGS = 'ags',
}

const DEFAULT_SITE_ID = SiteId.WILEYCOM;
const SUBSCRIPTION_SITE_IDS = [SiteId.COD, SiteId.AGS, SiteId.VET];

export const COOKIE_DOMAIN = '.wiley.com';
export const AGS_COOKIE_DOMAIN = '.graphicstandards.com';
const SITE_ID_URL_PARAM_KEY = 'site-id';

export const hostUrlToSiteIdMap: Readonly<Record<string, SiteId>> = {
  [WILEYCOM_HOST_URL]: SiteId.WILEYCOM,
  [VET_HOST_URL]: SiteId.VET,
  [AGS_HOST_URL]: SiteId.AGS,
};

const COD_CONTINUE_SHOPPING_PATH = '/shop/on-demand/chemistry';

export class SiteConfig {
  private static instance: SiteConfig | null = null;

  private readonly _siteId: SiteId;

  private readonly _hostUrl: string;

  private readonly _cookieDomain: string;

  private readonly _locale: string;

  private readonly _lang: string;

  private readonly _iso: string;

  private readonly _isSubscriptionSite: boolean;

  private constructor(hostUrl?: string, locale?: string) {
    if (!hostUrl) {
      const { protocol, hostname } = window.location;
      hostUrl = `${protocol}//${hostname}`;
    }

    if (Object.keys(hostUrlToSiteIdMap).includes(hostUrl)) {
      this._hostUrl = hostUrl;
    } else {
      logWarning(
        `SiteConfig: Unknown domain: ${hostUrl} - defaulting to ${DEFAULT_SITE_ID} host URL`,
      );
      this._hostUrl = WILEYCOM_HOST_URL;
    }

    const localeFromUrl = window.location.pathname.slice(1, 6) as string;
    const validLocale = VALID_LOCALES.includes(localeFromUrl) ? localeFromUrl : DEFAULT_LOCALE;
    this._locale = locale || validLocale;

    const [lang, iso] = this._locale.split('-');
    this._lang = lang;
    this._iso = iso;

    let siteId = hostUrlToSiteIdMap[hostUrl] || DEFAULT_SITE_ID;
    if (
      siteId === SiteId.WILEYCOM
      && getSearchParamFromUrl(SITE_ID_URL_PARAM_KEY) === SiteId.COD
    ) {
      siteId = SiteId.COD;
    }

    this._siteId = siteId;
    this._isSubscriptionSite = SUBSCRIPTION_SITE_IDS.includes(this._siteId);
    this._cookieDomain = siteId === SiteId.AGS
      ? AGS_COOKIE_DOMAIN : COOKIE_DOMAIN;

    Object.freeze(this);
  }

  public static init(hostUrl?: string, locale?: string): void {
    if (this.instance) {
      logWarning('SiteConfig: Already initialized.');
      return;
    }

    this.instance = new SiteConfig(hostUrl, locale);
  }

  public static getConfig(): SiteConfig {
    if (!this.instance) {
      logWarning('SiteConfig: Not initialized. Initializing...');
      this.init();
    }

    return this.instance!;
  }

  public get siteId(): SiteId {
    return this._siteId;
  }

  public get hostUrl(): string {
    return this._hostUrl;
  }

  public get locale(): string {
    return this._locale;
  }

  public get lang(): string {
    return this._lang;
  }

  public get iso(): string {
    return this._iso;
  }

  public get isSubscriptionSite(): boolean {
    return this._isSubscriptionSite;
  }

  public get cookieDomain(): string {
    return this._cookieDomain;
  }

  public static buildContinueShoppingUrl(): string {
    const { siteId, locale, hostUrl } = this.getConfig();
    const localeStr = [SiteId.COD, SiteId.WILEYCOM].includes(siteId)
      ? `/${locale}`
      : '';

    let path = '';
    if (siteId === SiteId.COD) {
      path = COD_CONTINUE_SHOPPING_PATH;
    }

    return `${hostUrl}${localeStr}${path}`;
  }

  public static buildRedirectUrl(
    redirectDomain: string,
    redirectPath: string,
    queryParams?: Record<string, string>,
  ): string {
    const { locale, siteId, hostUrl } = this.getConfig();
    let urlLocaleStr = '';
    let baseUrl = hostUrl;

    if ([SiteId.COD, SiteId.WILEYCOM].includes(siteId)) {
      urlLocaleStr = `/${locale}`;
      baseUrl = redirectDomain;
    }

    let redirectUrl = `${baseUrl}${urlLocaleStr}${redirectPath}`;

    const params = new URLSearchParams();
    if (queryParams) {
      Object.entries(queryParams).forEach(([key, value]) => params.set(key, value));
    }

    if (siteId === SiteId.COD) params.set(SITE_ID_URL_PARAM_KEY, siteId);

    if (params.toString()) {
      redirectUrl += `?${params.toString()}`;
    }

    return redirectUrl;
  }

  /** @internal Used for testing only */
  public static reset(): void {
    this.instance = null;
  }
}
