import { registrationAction } from '@store/slices/registration/actions/registration';
import { createSlice } from '@reduxjs/toolkit';
import { ALM_COOKIE_KEY, VIAX_COOKIE_KEY } from '@config/constants';
import {
  setCookie, onRegistrationSuccess, onRegistrationError, SiteConfig,
} from '@utils';
import { ResponseStatus } from '@graphql/registration';
import { HttpStatusCode } from '@types';
import { TurnstileCaptchaService } from '@services';
import i18n from '@localization/i18n';

interface RegistrationState {
  isLoading: boolean;
  error: boolean;
  errorCode?: HttpStatusCode;
}

const initialState: RegistrationState = {
  isLoading: false,
  error: false,
  errorCode: undefined,
};

export const registrationSlice = createSlice({
  name: 'registration',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(registrationAction.pending, (state) => {
        state.isLoading = true;
        state.error = false;
      })
      .addCase(registrationAction.fulfilled, (state, { payload: { wEcomRegistration, redirectUrl } }) => {
        const { status, data, errors } = wEcomRegistration.result;
        state.isLoading = false;

        if (status === ResponseStatus.OK) {
          setCookie(ALM_COOKIE_KEY, data?.almToken);
          setCookie(VIAX_COOKIE_KEY, data?.viaxToken);
          onRegistrationSuccess();

          window.location.href = redirectUrl || SiteConfig.buildContinueShoppingUrl();
        }

        if (status === ResponseStatus.ERROR) {
          state.error = true;
          state.errorCode = errors?.[0].code;
          TurnstileCaptchaService.reset();
          const errorMsg = i18n.t(`registration.form.validation.errors.codes.${errors?.[0].code}`);
          const formInfo = errors?.[0].code === HttpStatusCode.CONFLICT ? i18n.t('registration.form.signIn') : '';
          onRegistrationError(`${errorMsg}${formInfo}`);
        }
      })
      .addCase(registrationAction.rejected, (state) => {
        state.isLoading = false;
        state.error = true;
        state.errorCode = HttpStatusCode.BAD_REQUEST;
        TurnstileCaptchaService.reset();
        onRegistrationError(i18n.t(`registration.form.validation.errors.codes.${HttpStatusCode.BAD_REQUEST}`));
      });
  },
});

export const registrationReducer = registrationSlice.reducer;
