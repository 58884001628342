import React from 'react';
import { Provider } from 'react-redux';

import type { ChildrenProps } from '@types';
import { store } from '@store';
import { AEMResourceProvider } from '@hooks';

export const Providers: React.FC<ChildrenProps> = ({ children }) => (
  <Provider store={store}>
    <AEMResourceProvider>
      {children}
    </AEMResourceProvider>
  </Provider>
);
