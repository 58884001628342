import { configureStore } from '@reduxjs/toolkit';
import { guestReducer } from '@store/slices/guest/guestSlice';
import { loginReducer } from '@store/slices/login/loginSlice';
import { registrationReducer } from './slices/registration';

export const store = configureStore({
  reducer: {
    guest: guestReducer,
    login: loginReducer,
    registration: registrationReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
