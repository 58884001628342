import { InfoSection } from '@components/InfoSection';
import { useTranslation } from 'react-i18next';
import './style.scss';
import { RegistrationForm } from '@components/RegistrationForm';

export const RegistrationPage = () => {
  const { t } = useTranslation();

  return (
    <div className="registration-page">
      <InfoSection
        title={t('registration.continueToCheckout')}
        subtitle={t('registration.createWileyAccountToCheckout')}
      />
      <RegistrationForm />
    </div>
  );
};
