export const LoadInlineScript = (script: string) => {
  const inLineScript = document.createElement('script');
  inLineScript.defer = true;
  const scriptText = document.createTextNode(script);
  inLineScript.appendChild(scriptText);
  document.getElementsByTagName('head')[0].appendChild(inLineScript);
};

export const LoadExternalScript = (url: string) => {
  const externalScript = document.createElement('script');
  externalScript.type = 'text/javascript';
  externalScript.src = url;
  externalScript.defer = true;
  document.getElementsByTagName('head')[0].appendChild(externalScript);
};

export const LoadExternalStyle = (url: string, onload: ((this: GlobalEventHandlers, ev: Event) => any) | null) => {
  const externalStyle = document.createElement('link');
  externalStyle.rel = 'stylesheet';
  externalStyle.href = url;
  externalStyle.onload = onload;
  document.getElementsByTagName('head')[0].appendChild(externalStyle);
};
