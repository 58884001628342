import {
  createContext, useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import {
  LoadExternalScript,
  LoadExternalStyle,
  LoadInlineScript,
} from '@utils';
import { AEM_RESOURCE_BASE_URL } from '@config/constants';
import { getHeaderFooter, HeaderFooter } from './getHeaderFooter';
import { triggerAEMHeaderCartIcon } from './utils';

type UseAEMResourceState = Pick<HeaderFooter, 'header' | 'footer'>;
type UseAEMResource = UseAEMResourceState & {
  stylesLoaded: boolean;
};

const initialState: UseAEMResource = {
  header: '',
  footer: '',
  stylesLoaded: false,
};

const AEMResourceContext = createContext(initialState);

export const AEMResourceProvider = ({ children }: { children: React.ReactNode }) => {
  const [stylesLoaded, setStylesLoaded] = useState(false);
  const [state, setState] = useState<UseAEMResourceState>({
    header: initialState.header,
    footer: initialState.footer,
  });

  const updateStyles = (styles: NodeListOf<HTMLStyleElement> | []) => {
    let stylesCount = styles.length;
    if (stylesCount === 0) setStylesLoaded(true);

    const onload = () => {
      stylesCount -= 1;
      if (stylesCount <= 0) setStylesLoaded(true);
    };

    styles.forEach((style) => {
      const styleSrc = style.getAttribute('href');
      const isExternalStyle = styleSrc && styleSrc.startsWith('https');

      if (!isExternalStyle) LoadExternalStyle(AEM_RESOURCE_BASE_URL + styleSrc, onload);
      if (isExternalStyle) onload();
    });
  };

  const updateScripts = (scripts: NodeListOf<HTMLScriptElement> | []) => {
    scripts.forEach((script) => {
      const scriptSrc = script.getAttribute('src');

      if (!scriptSrc) {
        LoadInlineScript(script.innerHTML);
        return;
      }

      // Check if it's a wiley clientlib script
      const clientlibsIndex = scriptSrc.indexOf('/etc.clientlibs/');

      if (clientlibsIndex === -1) {
        LoadExternalScript(scriptSrc);
        return;
      }

      const externalScriptUrl = AEM_RESOURCE_BASE_URL + scriptSrc.slice(clientlibsIndex);
      LoadExternalScript(externalScriptUrl);
    });
  };

  const getHtml = useCallback(async () => {
    const response = await getHeaderFooter();
    updateStyles(response.styles);
    updateScripts(response.scripts);
    setState({ ...response });
  }, []);

  useEffect(() => {
    getHtml();
  }, [getHtml]);

  useEffect(() => {
    triggerAEMHeaderCartIcon();
  }, [stylesLoaded]);

  const payload = useMemo(() => ({ ...state, stylesLoaded }), [state, stylesLoaded]);

  return (
    <AEMResourceContext.Provider value={payload}>
      {children}
    </AEMResourceContext.Provider>
  );
};

export const useAEMResource = (): UseAEMResource => {
  const context = useContext(AEMResourceContext);

  if (!context) {
    throw new Error('useAEMResource must be used within a AEMResourceProvider');
  }
  return context;
};
