import { ONE_HOUR_IN_MILLISECONDS } from '@config/constants';
import { SiteConfig } from './SiteConfig';

export const getCookieValue = (name: string): string => {
  const escapedName = name.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  return (
    document.cookie
      .match(new RegExp(`(^|;)\\s*${escapedName}\\s*=\\s*([^;]+)`))
      ?.pop() || ''
  );
};

export const buildExpirationDate = (hours: number = 1): string => {
  const date = new Date();
  date.setTime(date.getTime() + hours * ONE_HOUR_IN_MILLISECONDS);
  return date.toUTCString();
};

export const setCookie = (name: string, value: string = '', expires?: string) => {
  const cookieName = encodeURIComponent(name);
  const cookieValue = encodeURIComponent(value);
  const { cookieDomain } = SiteConfig.getConfig();
  const cookieExpiration = expires ? `Expires=${expires};` : '';

  const cookie = `${cookieName}=${cookieValue};${cookieExpiration}domain=${cookieDomain};SameSite=Lax;path=/`;
  document.cookie = cookie;
};

export const getCartId = (): string | null => {
  const { siteId, locale } = SiteConfig.getConfig();
  const cartIdKey = `${siteId}-cart-${locale}`;
  const cartId = getCookieValue(cartIdKey);

  return cartId || null;
};
