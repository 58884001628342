type StringArg = string | number | null | undefined;
type ObjArg = Record<string, any>;
type ArrArg = Array<StringArg | ObjArg | ArrArg>;
type ClsxArgs = Array<StringArg | ObjArg | ArrArg>;

// simple helper for classnames handling
export function cn(...args: ClsxArgs): string {
  return args
    .flat()
    .map((x) => {
      if (typeof x === 'string') return x;
      if (typeof x === 'object' && x !== null) {
        let keys = x;
        if (!Array.isArray(x)) {
          keys = Object.entries(x).filter((v) => !!v[1]).map(([c]) => c);
        }
        return keys.join(' ');
      }
      return '';
    })
    .join(' ')
    .trim();
}
