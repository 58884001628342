import { createSlice } from '@reduxjs/toolkit';
import { getCart } from '@store/slices/guest/actions/getCart';
import { loginGuest } from '@store/slices/guest/actions/loginGuest';
import { ResponseStatus } from '@graphql/registration';
import {
  buildExpirationDate, setCookie,
  checkIsGuestAllowed,
  SiteConfig,
} from '@utils';
import {
  ALM_COOKIE_KEY, VIAX_COOKIE_KEY, ONE_HOUR_INTEGER, CHECKOUT_PATH,
  CHECKOUT_BASE_URL,
} from '@config/constants';

interface Login {
  isGuestAllowed: boolean;
  isGuestLoading: boolean;
  loginGuestError: boolean;
}

const initialState: Login = {
  isGuestAllowed: false,
  isGuestLoading: false,
  loginGuestError: false,
};

export const guestSlice = createSlice({
  name: 'guest',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCart.fulfilled, (state, { payload: { wGetCart } }) => {
        const products = wGetCart.cart.hiConsistsOf;
        state.isGuestAllowed = products.length > 0 && checkIsGuestAllowed(products);
      })
      .addCase(loginGuest.pending, (state) => {
        state.isGuestLoading = true;
        state.loginGuestError = false;
      })
      .addCase(loginGuest.fulfilled, (state, { payload: { wEcomGuest } }) => {
        const { status, data } = wEcomGuest.result;
        state.isGuestLoading = false;

        if (status === ResponseStatus.OK) {
          setCookie(ALM_COOKIE_KEY, data?.almToken, buildExpirationDate(ONE_HOUR_INTEGER));
          setCookie(VIAX_COOKIE_KEY, data?.viaxToken, buildExpirationDate(ONE_HOUR_INTEGER));

          window.location.href = SiteConfig.buildRedirectUrl(CHECKOUT_BASE_URL, CHECKOUT_PATH);
        }
        if (status === ResponseStatus.ERROR) {
          state.loginGuestError = true;
        }
      })
      .addCase(loginGuest.rejected, (state) => {
        state.isGuestLoading = false;
        state.loginGuestError = true;
      });
  },
});

export const guestReducer = guestSlice.reducer;
