import { ClipboardEventHandler, DragEvent } from 'react';

export interface DisabledInputFunctions {
  onPaste?: ClipboardEventHandler<HTMLInputElement>,
  onCut?: ClipboardEventHandler<HTMLInputElement>,
  onCopy?: ClipboardEventHandler<HTMLInputElement>,
  onDrag?: (e: DragEvent<HTMLInputElement>) => void,
  onDrop?: (e: DragEvent<HTMLInputElement>) => void,
  autoComplete?: 'off' | 'on',
}

export const disabledInputFunctions: DisabledInputFunctions = {
  onPaste: (e) => {
    e.preventDefault();
    return false;
  },
  onCut: (e) => {
    e.preventDefault();
    return false;
  },
  onCopy: (e) => {
    e.preventDefault();
    return false;
  },
  onDrop: (e) => {
    e.preventDefault();
    return false;
  },
  onDrag: (e) => {
    e.preventDefault();
    return false;
  },
  autoComplete: 'off',
};
