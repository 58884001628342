import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from '@api';
import { getCartId } from '@utils';
import { SERVER_ERROR } from '@config/constants';
import { LOGIN_GUEST_MUTATION, LoginGuestResponse } from '@graphql/guest';

export const loginGuest = createAsyncThunk<LoginGuestResponse>(
  'guest/loginGuest',
  async (_, { rejectWithValue }) => {
    try {
      const orderUid = getCartId();
      const result = await client.request<LoginGuestResponse>(LOGIN_GUEST_MUTATION, { orderUid });
      return result;
    } catch (error) {
      return rejectWithValue(SERVER_ERROR);
    }
  },
);
