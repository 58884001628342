import { HttpStatusCode } from '@types';
import { gql } from 'graphql-request';

interface Error {
  code: HttpStatusCode;
  message: string;
}

export enum ResponseStatus {
  'ERROR' = 'ERROR',
  'OK' = 'OK',
}

interface RegistrationData {
  almToken: string;
  viaxToken: string;
}

export interface RegistrationOutput {
  redirectUrl: string | null;
  wEcomRegistration: {
    result: {
      status: ResponseStatus;
      data?: RegistrationData;
      errors?: Error[];
    }
  }
}

interface InputData {
  captchaToken: string;
  country: string;
  email: string;
  password: string;
  optIn: boolean;
}

export interface RegistrationInput {
  inputData: InputData;
  redirectUrl: string | null;
}

export const REGISTRATION_MUTATION = gql`
  mutation registration(
      $captchaToken: String!,
      $country: String!,
      $email: String!,
      $password: String!,
      $optIn: Boolean!,
      $siteId: String!,
      $orderUid: String
    ) {
    wEcomRegistration(
      captchaToken: $captchaToken
      country: $country
      email: $email
      password: $password
      optIn: $optIn
      siteId: $siteId
      orderUid: $orderUid
    )
  }
`;
