import './style.scss';
import { ReactComponent as CartIcon } from '../../assets/images/cart.svg';

interface InfoSectionProps {
  title: string;
  subtitle: string;
}

export const InfoSection = (props: InfoSectionProps) => {
  return (
    <div className="info-section" data-testid="info-section">
      <CartIcon className="info-section__icon" />
      <div className="info-section__description">
        <span className="info-section__title text-subtitle-1">
          { props.title }
        </span>
        <span className="info-section__subtitle text-subtitle-2">
          { props.subtitle}
        </span>
      </div>
    </div>
  );
};
