export const GRAPHQL_URI = process.env.REACT_APP_VIAX_GRAPHQL_URI;
export const TURNSTILE_DATA_SITE_KEY = process.env.REACT_APP_TURNSTILE_DATA_SITE_KEY;

// #start shared-global header/footer
export const AEM_RESOURCE_BASE_URL = process.env.REACT_APP_AEM_RESOURCE_BASE_URL as string;
// eslint-disable-next-line max-len
export const HEADER_FOOTER_BASE_URL = `${AEM_RESOURCE_BASE_URL}/content/experience-fragments/wiley-dotcom/{country}/{lang}/consumer/site/global/api/master.xfheaderfootercontent.html`;
export const CLICKABLE_HEADER_FOOTER_ROUTE = '/slim-global-header';
export const NOT_CLICKABLE_HEADER_FOOTER_ROUTE = '/slim-global-header-microsites';
export const AEM_HEADER_HEIGHT = 80;
// #end shared-global header/footer

export const DEFAULT_LOCALE = 'en-us';
export const LOCALES_MAP = {
  enAU: 'en-au',
  enBE: 'en-be',
  enBR: 'en-br',
  enCA: 'en-ca',
  enCN: 'en-cn',
  enDK: 'en-dk',
  enFR: 'en-fr',
  enGB: 'en-gb',
  enDE: 'en-de',
  enHK: 'en-hk',
  enIN: 'en-in',
  enIE: 'en-ie',
  enIT: 'en-it',
  enJP: 'en-jp',
  enMX: 'en-mx',
  enNL: 'en-nl',
  enNZ: 'en-nz',
  enNO: 'en-no',
  enSG: 'en-sg',
  enKR: 'en-kr',
  enES: 'en-es',
  enSE: 'en-se',
  enCH: 'en-ch',
  enAE: 'en-ae',
  enUS: 'en-us',
  deDE: 'de-de',
};
export const SCREEN_SIZE = {
  MOBILE: 790,
};
export const SERVER_ERROR = 'SERVER_ERROR';

export const PAGE_ROUTES = {
  LOGIN: '/:locale/login',
  REGISTRATION: '/:locale/register',
  PASSWORD_RECOVERY: '/:locale/forgot-password',
};

export const FORGOT_PASSWORD_REDIRECT_URL = process.env.REACT_APP_FORGOT_PASSWORD_REDIRECT_URL as string;
export const CHECKOUT_BASE_URL = process.env.REACT_APP_CHECKOUT_BASE_URL as string;
export const CHECKOUT_PATH = '/checkout';

export const ALM_COOKIE_KEY = 'ALM-token';
export const VIAX_COOKIE_KEY = 'wiley-b2c-auth-token';

export const ONE_HOUR_IN_MILLISECONDS = 60 * 60 * 1000;
export const ONE_HOUR_INTEGER = 1;

export const PRICE_MODEL = 'WILEYCOM';

export const WILEYCOM_HOST_URL = process.env
  .REACT_APP_WILEYCOM_HOST_URL as string;
export const VET_HOST_URL = process.env.REACT_APP_VET_HOST_URL as string;
export const AGS_HOST_URL = process.env.REACT_APP_AGS_HOST_URL as string;

export const VALID_LOCALES = [
  'en-us',
  'en-au',
  'en-be',
  'en-br',
  'en-ca',
  'en-cn',
  'en-dk',
  'en-fr',
  'en-gb',
  'en-de',
  'de-de',
  'en-hk',
  'en-in',
  'en-ie',
  'en-it',
  'en-jp',
  'en-mx',
  'en-nl',
  'en-nz',
  'en-no',
  'en-sg',
  'en-kr',
  'en-es',
  'en-se',
  'en-ch',
  'de-ch',
  'en-ae',
];
