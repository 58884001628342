import React from 'react';
import type { ChildrenProps } from '@types';

class ErrorBoundary extends React.Component<ChildrenProps, { hasError: boolean }> {
  constructor(props: ChildrenProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    /* eslint-disable-next-line no-console */
    console.error(error, errorInfo);
  }

  render() {
    return this.state.hasError ? <h1>Something went wrong...</h1> : this.props.children;
  }
}

export { ErrorBoundary };
