import { createSlice } from '@reduxjs/toolkit';
import { loginAction } from '@store/slices/login/actions/login';
import { ResponseStatus } from '@graphql/registration';
import { TurnstileCaptchaService } from '@services';

import {
  buildExpirationDate, setCookie, onSignInSuccess, onSignInError,
  SiteConfig,
} from '@utils';
import {
  ALM_COOKIE_KEY, VIAX_COOKIE_KEY, ONE_HOUR_INTEGER,
} from '@config/constants';
import i18n from '@localization/i18n';

interface Login {
  isLoading: boolean;
  loginError: boolean;
}

const initialState: Login = {
  isLoading: false,
  loginError: false,
};

export const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loginAction.pending, (state) => {
        state.isLoading = true;
        state.loginError = false;
      })
      .addCase(loginAction.fulfilled, (state, { payload: { wEcomLogin, redirectUrl } }) => {
        const { status, data } = wEcomLogin.result;
        state.isLoading = false;

        if (status === ResponseStatus.OK) {
          setCookie(ALM_COOKIE_KEY, data?.almToken, buildExpirationDate(ONE_HOUR_INTEGER));
          setCookie(VIAX_COOKIE_KEY, data?.viaxToken, buildExpirationDate(ONE_HOUR_INTEGER));
          onSignInSuccess();

          window.location.href = redirectUrl || SiteConfig.buildContinueShoppingUrl();
        }
        if (status === ResponseStatus.ERROR) {
          state.loginError = true;
          TurnstileCaptchaService.reset();
          onSignInError(i18n.t('login.form.validation.errors.credentials'));
        }
      })
      .addCase(loginAction.rejected, (state) => {
        state.isLoading = false;
        state.loginError = true;
        TurnstileCaptchaService.reset();
        onSignInError(i18n.t('login.form.validation.errors.credentials'));
      });
  },
});

export const loginReducer = loginSlice.reducer;
