import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  regCapital,
  regLetter,
  regDigit,
  regSpecial,
  regPass,
  cn,
} from '@utils';

import './style.scss';

interface PasswordRequirementsProps {
  field: string;
}

export const PasswordRequirements = (props: PasswordRequirementsProps) => {
  const methods = useFormContext();
  const { t } = useTranslation('', {
    keyPrefix: 'registration.form.validation.password',
  });

  const fieldValue = methods.watch(props.field);
  const { isDirty } = methods.getFieldState(props.field);

  return (
    <ul className="validation-rules text-body-3">
      <li
        className={cn('validation-rules__item', [
          isDirty && fieldValue && fieldValue.length > 9
          && 'valid',
          isDirty && fieldValue && fieldValue.length <= 9
          && 'not-valid',
        ])}
      >
        {t('10char')}
      </li>
      <li
        className={cn('validation-rules__item', [
          isDirty && RegExp(regPass).test(fieldValue) && 'valid',
          isDirty && !RegExp(regPass).test(fieldValue) && 'not-valid',
        ])}
      >
        {t('3categories')}
        <ul className="validation-rules__item-list">
          <li
            className={cn('validation-rules__item-list__item', [
              RegExp(regCapital).test(fieldValue)
                && 'valid',
            ])}
          >
            {t('uppercase')}
          </li>
          <li
            className={cn('validation-rules__item-list__item', [
              RegExp(regLetter).test(fieldValue)
                && 'valid',
            ])}
          >
            {t('lowercase')}
          </li>
          <li
            className={cn('validation-rules__item-list__item', [
              RegExp(regDigit).test(fieldValue) && 'valid',
            ])}
          >
            {t('num')}
          </li>
          <li
            className={cn('validation-rules__item-list__item', [
              RegExp(regSpecial).test(fieldValue) && 'valid',
            ])}
          >
            {t('specialChar')}
          </li>
        </ul>
      </li>
    </ul>
  );
};
