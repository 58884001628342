import { InfoSection } from '@components/InfoSection';
import './style.scss';
import { LoginForm } from '@components/LoginForm';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

export const LoginPage = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const isMinimal = (searchParams.get('view') === 'minimal');

  return (
    <div className="login-page" data-testid="login-page">
      {!isMinimal && (
        <InfoSection
          title={t('login.continueToCheckout')}
          subtitle={t('login.signInToWileyAccount')}
        />
      )}
      <LoginForm />
    </div>
  );
};
