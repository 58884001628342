import { cn } from '@utils';
import './style.scss';
import { Link, To } from 'react-router-dom';

export interface CommonLinkProps {
  url: To;
  title: string;
  target?: string;
  className?: string;
  onClick?: () => void;
  type?: 'primary' | 'warning';
  htmlLink?: boolean;
}

export const CommonLink = (props: CommonLinkProps) => {
  if (props.htmlLink) {
    return (
      <a
        onClick={props.onClick}
        className={cn('common-link', [
          props.className || '',
          `common-link--${props.type}`,
        ])}
        href={props.url as string}
        target={props.target}
      >
        {props.title}
      </a>
    );
  }

  return (
    <Link
      onClick={props.onClick}
      className={cn('common-link', [
        props.className || '',
        `common-link--${props.type}`,
      ])}
      to={props.url}
      target={props.target}
    >
      {props.title}
    </Link>
  );
};

CommonLink.defaultProps = {
  type: 'primary',
};
