import { gql } from 'graphql-request';

export enum EditionFormat {
  DIGITAL = 'DIGITAL',
  PHYSICAL = 'PHYSICAL',
  DIGITAL_AND_PHYSICAL = 'DIGITAL_AND_PHYSICAL',
}

interface Category {
  catId: string;
}

interface ViaxBiiSalable {
  wEditionFormat: EditionFormat;
  maCategories: Category[];
}

export interface ViaxOrderItem {
  biiSalable: ViaxBiiSalable;
}

interface ViaxOrder {
  uid: string;
  hiConsistsOf: ViaxOrderItem[];
}

export interface GetCartResponse {
  wGetCart: {
    cart: ViaxOrder;
  }
}

export const GET_CART_QUERY = gql`
  mutation getCart($uid: UUID) {
    wGetCart(uid: $uid)
  }
`;
