import { SiteConfig } from '@utils';
import {
  CLICKABLE_HEADER_FOOTER_ROUTE,
  HEADER_FOOTER_BASE_URL,
  NOT_CLICKABLE_HEADER_FOOTER_ROUTE,
} from '@config/constants';

export const buildHeaderFooterUrl = (): string => {
  const {
    siteId, isSubscriptionSite, lang, iso,
  } = SiteConfig.getConfig();

  const baseUrl = HEADER_FOOTER_BASE_URL
    .replace('{country}', iso)
    .replace('{lang}', lang);

  if (isSubscriptionSite) {
    return `${baseUrl}${NOT_CLICKABLE_HEADER_FOOTER_ROUTE}?site-id=${siteId}`;
  }
  return `${baseUrl}${CLICKABLE_HEADER_FOOTER_ROUTE}`;
};

export const triggerAEMHeaderCartIcon = () => {
  const { siteId, locale } = SiteConfig.getConfig();

  document.dispatchEvent(
    new CustomEvent('wly-cart:updated', {
      detail: {
        locale,
        site: siteId,
      },
    }),
  );
};
