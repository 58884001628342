import './style.scss';

import { useCallback, useEffect } from 'react';
import {
  useForm, FormProvider, Form,
} from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';

import { CommonButton } from '@components/common/CommonButton';
import { PasswordInput } from '@components/PasswordInput';
// import { CommonCheckbox } from '@components/common/CommonCheckbox';
import { CommonLink } from '@components/common/CommonLink';
import { ErrorSection } from '@components/ErrorSection';
import { ValidationInput } from '@components/ValidationInput';
import { TurnstileCaptcha } from '@components/TurnstileCaptcha';

import { useAppDispatch, useAppSelector } from '@store';
import { loginAction } from '@store/slices/login/actions/login';
import { loginSelector } from '@store/slices/login/selectors';
import { guestSelector } from '@store/slices/guest/selectors';
import { loginGuest } from '@store/slices/guest/actions/loginGuest';
import { PAGE_ROUTES, FORGOT_PASSWORD_REDIRECT_URL } from '@config/constants';
import {
  LoginValidationSchema, disabledInputFunctions,
  onSignInPageLoad, onSignInError, onForgotPasswordClick, onContinueAsGuestClick, onCreateAccountLinkClick,
  SiteConfig,
} from '@utils';

export type FormValues = {
  email: string;
  password: string;
  captchaToken: string;
};

export const LoginForm = () => {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const { locale, iso: country } = SiteConfig.getConfig();
  const { isLoading, loginError } = useAppSelector(loginSelector);
  const { isGuestAllowed, isGuestLoading, loginGuestError } = useAppSelector(guestSelector);
  const { t } = useTranslation();

  const isMinimal = (searchParams.get('view') === 'minimal');

  const methods = useForm<FormValues>({
    defaultValues: {
      email: '',
      password: '',
      captchaToken: '',
    },
    mode: 'onChange',
    resolver: yupResolver(LoginValidationSchema()),
  });

  const onVerifyCaptcha = useCallback((token: string) => {
    methods.setValue('captchaToken', token);
    methods.clearErrors('captchaToken');
  }, [methods]);

  const loginHandler = methods.handleSubmit(async (data) => {
    const redirectUrl = searchParams.get('target');
    dispatch(loginAction({ inputData: { ...data, country }, redirectUrl }));
  });

  useEffect(() => {
    onSignInPageLoad(locale, country);
  }, [locale, country]);

  const adobeFormFieldsErrorhandler = () => {
    const errorKeys = Object.keys(methods.formState.errors) as Array<keyof FormValues>;
    const validationErrors = [] as Array<string>;
    if (errorKeys.length > 0) {
      errorKeys.forEach((key) => {
        const clientError = methods.formState.errors[key];
        if (clientError && clientError.message) {
          validationErrors.push(clientError.message);
        }
      });
      if (validationErrors.length > 0) {
        onSignInError(validationErrors.join(' | '));
      }
    }
  };

  const handleLogin = async () => {
    await loginHandler();
    adobeFormFieldsErrorhandler();
  };

  const handleGuestLogin = () => {
    onContinueAsGuestClick(t('guest.checkout'));
    dispatch(loginGuest());
    adobeFormFieldsErrorhandler();
  };

  const registrationPathname = PAGE_ROUTES.REGISTRATION.replace(':locale', locale);
  const registrationUrl = `${registrationPathname}${window.location.search}`;

  const forgotPasswordPathname = FORGOT_PASSWORD_REDIRECT_URL.replace('{locale}', locale);
  const forgotPasswordUrl = `${forgotPasswordPathname}?target=${window.location.href}`;

  const handleForgotPasswordClick = () => {
    onForgotPasswordClick(t('login.form.forgotPassword'), forgotPasswordUrl);
  };

  const handleCreateAccountLinkClick = () => {
    onCreateAccountLinkClick(registrationUrl);
  };

  return (
    <div className="login-form" data-testid="login-form">
      <h2 className="login-form__title">
        {t('login.form.title')}
      </h2>

      {!isMinimal && (
        <div className="login-form__create-account text-subtitle-2">
          <span>
            {t('login.form.newToWiley')}
          </span>
          <CommonLink
            url={registrationUrl}
            title={t('login.form.createAccount')}
            onClick={handleCreateAccountLinkClick}
          />
        </div>
      )}

      {(loginError || loginGuestError) && (
        <ErrorSection>
          {loginError ? t('login.form.validation.errors.credentials') : t('guest.error')}
        </ErrorSection>
      )}

      <FormProvider {...methods} data-testid="login-form">
        <Form className="login-form__fields">
          <ValidationInput
            className="login-form__fields--email"
            name="email"
            placeholder={t('login.form.email.placeholder')}
            label={t('login.form.email.label')}
            disabledInputFunctions={disabledInputFunctions}
          />
          <PasswordInput
            className="login-form__fields--password"
            name="password"
            label={t('login.form.password')}
            placeholder={t('login.form.passwordPlaceholder')}
          />
          <TurnstileCaptcha
            action="login"
            onVerifyCaptcha={onVerifyCaptcha}
          />
          {methods.formState.errors.captchaToken && (
            <p className="common-input__error-message text-subtitle-2">
              {t('login.form.validation.errors.captchaRequire')}
            </p>
          )}
        </Form>
      </FormProvider>

      <div className="login-form__password-actions">
        { /* <CommonCheckbox label={t('login.form.rememberMe')} /> */}
        <CommonLink
          htmlLink
          className="login-form__reset-password--link"
          url={forgotPasswordUrl}
          title={t('login.form.forgotPassword')}
          onClick={handleForgotPasswordClick}
        />
      </div>

      <div className="login-form__actions">
        <CommonButton
          type="primary"
          disabled={isLoading || isGuestLoading}
          loading={isLoading}
          onClick={handleLogin}
        >
          {!isMinimal ? t('login.form.signInToCheckout') : t('login.form.signIn')}
        </CommonButton>
        {isGuestAllowed && (
          <CommonButton
            type="secondary"
            disabled={isLoading || isGuestLoading}
            loading={isGuestLoading}
            onClick={handleGuestLogin}
          >
            {t('guest.checkout')}
          </CommonButton>
        )}
      </div>
    </div>
  );
};
