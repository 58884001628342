import { useEffect, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { TURNSTILE_DATA_SITE_KEY } from '@config/constants';
import './style.scss';
import { TurnstileCaptchaService } from '@services';
import type { TurnstileCaptchaAction } from '@services';

type CaptchaProps = {
  onVerifyCaptcha: (token: string) => void;
  action: TurnstileCaptchaAction
};

export const TurnstileCaptcha = memo(({ onVerifyCaptcha, action }: CaptchaProps) => {
  const { t } = useTranslation();

  window.onloadTurnstileCallback = () => {
    TurnstileCaptchaService.setInstance(window.turnstile);

    if (!TURNSTILE_DATA_SITE_KEY) return;

    TurnstileCaptchaService.render({
      elementId: '#turnstile-container',
      config: {
        siteKey: TURNSTILE_DATA_SITE_KEY,
        action,
        callback: onVerifyCaptcha,
      },
    });
  };

  window.onVerifyCaptcha = (token) => onVerifyCaptcha(token);

  useEffect(() => {
    const script = document.createElement('script');

    script.src = 'https://challenges.cloudflare.com/turnstile/v0/api.js?onload=onloadTurnstileCallback';
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="turnstile" data-testid="turnstile-captcha">
      <span className="turnstile__title">{t('login.turnstileCaptcha')}</span>
      <div
        id="turnstile-container"
        className="turnstile__captcha"
      />
    </div>
  );
});
