import { gql } from 'graphql-request';

interface Error {
  code: string;
  message: string;
}

interface LoginData {
  almToken: string;
  viaxToken: string;
}

export interface LoginOutput {
  redirectUrl: string | null;
  wEcomLogin: {
    result: {
      status: 'ERROR' | 'OK';
      data?: LoginData;
      errors?: Error[];
    }
  }
}

interface InputData {
  captchaToken: string;
  country: string;
  email: string;
  password: string;
}

export interface LoginInput {
  inputData: InputData;
  redirectUrl: string | null;
}

export const LOGIN_MUTATION = gql`
  mutation login($captchaToken: String!, $country: String!, $email: String!, $password: String!, $orderUid: String) {
    wEcomLogin(
      captchaToken: $captchaToken
      country: $country
      email: $email
      password: $password
      orderUid: $orderUid
    )
  }
`;
