import React from 'react';
import ReactDOM from 'react-dom/client';

import '@localization/i18n';
import { ErrorBoundary } from '@components/ErrorBoundary';
import { BrowserRouter } from 'react-router-dom';
import { Providers } from './Providers';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <ErrorBoundary>
      <Providers>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Providers>
    </ErrorBoundary>
  </React.StrictMode>,
);
