import { LOCALES_MAP } from '@config/constants';
import enUS from './en-us.json';
import deDE from './de-de.json';

const localizations = {
  [LOCALES_MAP.enUS]: enUS,
  [LOCALES_MAP.deDE]: deDE,
};

export default localizations;
