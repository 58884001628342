import i18n, { InitOptions } from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import { LOCALES_MAP, DEFAULT_LOCALE } from '@config/constants';
import localizations from './localizations';

type Translations = {
  [key: string]: string | Translations;
};

const LOCALES = Object.values(LOCALES_MAP);

const optionsLang = {
  order: ['path', 'cookie', 'localStorage', 'sessionStorage', 'htmlTag'],

  // keys or params to lookup language from
  lookupCookie: 'i18next',
  lookupLocalStorage: 'i18nextLng',
  lookupSessionStorage: 'i18nextLng',
  lookupFromPathIndex: 0,

  // cache user language on
  caches: ['localStorage', 'cookie'],
};

const resources: Record<string, { translations: Translations }> = LOCALES.reduce((acc, locale) => {
  return { ...acc, [locale]: { translations: localizations[locale] } };
}, {});

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources,
    fallbackLng: DEFAULT_LOCALE,
    supportedLngs: LOCALES,
    detection: optionsLang,
    lowerCaseLng: true,
    debug: false,
    ns: ['translations'],
    defaultNS: 'translations',
    interpolation: {
      escapeValue: false,
      formatSeparator: ',',
    },
    react: {
      useSuspense: true,
    },
  } as InitOptions);

document.documentElement.lang = i18n.language.slice(0, 2);

export default i18n;
