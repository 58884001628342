import { useAEMResource } from '@hooks';
import { AEM_HEADER_HEIGHT } from "@config/constants";

const Header = () => {
  const { stylesLoaded, header } = useAEMResource();

  if (!stylesLoaded || !header) return <div style={{ height: `${AEM_HEADER_HEIGHT}px` }}></div>;
  return <header dangerouslySetInnerHTML={{ __html: header }} />;
}

export { Header };
