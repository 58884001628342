import './style.scss';
import { useMount } from '@utils';
import { useAppDispatch } from '@store';
import { Header } from '@components/Header';
import { Footer } from '@components/Footer';
import { LoginPage } from '@pages/LoginPage';
import {
  Navigate, Route, Routes,
} from 'react-router-dom';
import { RegistrationPage } from '@pages/RegistrationPage';
// import { PasswordRecoveryPage } from '@pages/PasswordRecoveryPage';
import { PAGE_ROUTES, DEFAULT_LOCALE } from '@config/constants';
import { getCart } from '@store/slices/guest/actions/getCart';

const App = () => {
  const dispatch = useAppDispatch();
  useMount(() => dispatch(getCart()));

  return (
    <>
      <Header />
      <main className="main-container">
        <Routes>
          <Route path={PAGE_ROUTES.LOGIN} element={<LoginPage />} />
          <Route path={PAGE_ROUTES.REGISTRATION} element={<RegistrationPage />} />
          {/* <Route path={PAGE_ROUTES.PASSWORD_RECOVERY} element={<PasswordRecoveryPage />} /> */}
          <Route path="*" element={<Navigate to={PAGE_ROUTES.LOGIN.replace(':locale', DEFAULT_LOCALE)} />} />
        </Routes>
      </main>
      <Footer />
    </>
  );
};

export default App;
