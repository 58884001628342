import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from '@api';
import { getCartId, SiteConfig } from '@utils';
import { SERVER_ERROR } from '@config/constants';
import { REGISTRATION_MUTATION, RegistrationInput, RegistrationOutput } from '@graphql/registration';

export const registrationAction = createAsyncThunk<RegistrationOutput, RegistrationInput>(
  'registration/registrationAction',
  async ({ inputData, redirectUrl }, { rejectWithValue }) => {
    try {
      const { siteId } = SiteConfig.getConfig();
      const orderUid = getCartId();
      const variables = { ...inputData, siteId, orderUid };
      const result = await client.request<RegistrationOutput>(REGISTRATION_MUTATION, variables);
      return { ...result, redirectUrl };
    } catch (error) {
      return rejectWithValue(SERVER_ERROR);
    }
  },
);
