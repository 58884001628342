export interface AdobeDataLayerEvent {
  event: string;
  environment?: string;
  page?: {
    pageName: string;
    section: string;
    type: string;
  };
  site?: {
    country: string;
    language: string;
    platform: string;
    name: string;
  };
  formName?: string;
  errorMsg?: string;
  cta?: {
    type: string;
    title: string;
    href: string;
    section: string;
  };
}

export const onRegistrationPageLoad = (locale: string, countryName: string) => {
  window.adobeDataLayer = window?.adobeDataLayer || [];
  const length = window?.adobeDataLayer?.length;
  if (length === 0 || window?.adobeDataLayer[length - 1]?.page?.pageName
    !== `www:wil:${countryName}:checkout:multi:create-account:add`) {
    window?.adobeDataLayer?.push({
      event: 'pageLoad',
      environment: process.env.REACT_APP_ANALYTICS_MODE as string,
      page: {
        pageName: `www:wil:${countryName}:checkout:multi:create-account:add`,
        section: 'checkout',
        type: 'checkout account create:viax cart',
      },
      site: {
        country: countryName,
        language: locale,
        platform: 'web',
        name: 'WIL',
      },
    });
  }
};

export const onRegistrationError = (msg: string) => {
  const eventBody = {
    event: 'createAccountFailure',
    formName: 'checkout:form:createAccount',
    errorMsg: msg,
    cta: {
      type: 'button',
      title: 'create account failure',
      href: '',
      section: '',
    },
  };
  window.adobeDataLayer = window?.adobeDataLayer || [];
  window?.adobeDataLayer?.push(eventBody);
};

export const onRegistrationSuccess = () => {
  const eventBody = {
    event: 'createAccountSuccess',
    formName: 'checkout:form:createAccount',
    cta: {
      type: 'button',
      title: 'create account success',
      href: '',
      section: '',
    },
  };
  window.adobeDataLayer = window?.adobeDataLayer || [];
  window?.adobeDataLayer?.push(eventBody);
};

export const onSignInClick = (btnTitle: string, url: string) => {
  window.adobeDataLayer = window.adobeDataLayer || [];
  window.adobeDataLayer.push({
    event: 'ctaClick',
    cta: {
      type: 'link',
      title: btnTitle,
      href: url,
      section: 'create account',
    },
  });
};

export const onSignInPageLoad = (locale: string, countryName: string) => {
  window.adobeDataLayer = window?.adobeDataLayer || [];
  const length = window?.adobeDataLayer?.length;
  if (length === 0 || window?.adobeDataLayer[length - 1]?.page?.pageName
    !== `www:wil:${countryName}:checkout:signIn`) {
    window?.adobeDataLayer?.push({
      event: 'pageLoad',
      environment: process.env.REACT_APP_ANALYTICS_MODE as string,
      page: {
        pageName: `www:wil:${countryName}:checkout:signIn`,
        section: 'checkout',
        type: 'checkout account sign in:viax cart',
      },
      site: {
        country: countryName,
        language: locale,
        platform: 'web',
        name: 'WIL',
      },
    });
  }
};

export const onSignInError = (msg: string) => {
  const eventBody = {
    event: 'signInFailure',
    formName: 'checkout:form:signInForm',
    errorMsg: msg,
    cta: {
      type: 'button',
      title: 'sign in failure',
      href: '',
      section: '',
    },
  };
  window.adobeDataLayer = window?.adobeDataLayer || [];
  window?.adobeDataLayer?.push(eventBody);
};

export const onSignInSuccess = () => {
  const eventBody = {
    event: 'signInSuccess',
    formName: 'checkout:form:signInForm',
    cta: {
      type: 'button',
      title: 'sign in success',
      href: '',
      section: '',
    },
  };
  window.adobeDataLayer = window?.adobeDataLayer || [];
  window?.adobeDataLayer?.push(eventBody);
};

export const onForgotPasswordClick = (btnTitle: string, url: string) => {
  window.adobeDataLayer = window.adobeDataLayer || [];
  window.adobeDataLayer.push({
    event: 'ctaClick',
    cta: {
      type: 'link',
      title: btnTitle,
      href: url,
      section: 'Sign In Form',
    },
  });
};

export const onContinueAsGuestClick = (btnTitle: string) => {
  window.adobeDataLayer = window.adobeDataLayer || [];
  window.adobeDataLayer.push({
    event: 'ctaClick',
    cta: {
      type: 'button',
      title: btnTitle.toLocaleLowerCase(),
      href: '',
      section: 'guest checkout',
    },
  });
};

export const onCreateAccountLinkClick = (url: string) => {
  window.adobeDataLayer = window.adobeDataLayer || [];
  window.adobeDataLayer.push({
    event: 'ctaClick',
    cta: {
      type: 'link',
      title: 'create an account link click',
      href: url,
      section: 'create account',
    },
  });
};
