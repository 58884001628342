import { cn, DisabledInputFunctions } from '@utils';
import './style.scss';

export interface CommonInputProps {
  value?: string;
  type?: string;
  className?: string;
  placeholder?: string;
  label?: string;
  icon?: React.ReactNode;
  onIconClick?: () => void;
  onChange?: () => void;
  onBlur?: () => void;
  error?: boolean;
  errorMessage?: string;
  children?: React.ReactNode;
  disabledInputFunctions?: DisabledInputFunctions;
}

export const CommonInput = (props: CommonInputProps) => {
  return (
    <>
      <div className={cn('common-input', [props.className || ''])}>
        {props.label && (
          <span className="common-input__label text-subtitle-2">{props.label}</span>
        )}
        <input
          className={props.error ? 'error' : ''}
          placeholder={props.placeholder}
          value={props.value}
          onChange={props.onChange}
          onBlur={props.onBlur}
          type={props.type || 'text'}
          {...props.disabledInputFunctions}
        />
        {
          props.icon && (
            <button className="common-input__icon" type="button" onClick={props.onIconClick}>
              {props.icon}
            </button>
          )
        }
      </div>
      {
        props.children && (
          <div className="common-input__children">{props.children}</div>
        )
      }
      {
        props.error && props.errorMessage && (
          <p className="common-input__error-message text-subtitle-2">
            {props.errorMessage}
          </p>
        )
      }
    </>
  );
};
