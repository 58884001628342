import { AGS_COOKIE_DOMAIN, COOKIE_DOMAIN } from '@utils';
import { buildHeaderFooterUrl } from './utils';

export interface HeaderFooter {
  header: string;
  footer: string;
  scripts: NodeListOf<HTMLScriptElement> | [];
  styles: NodeListOf<HTMLStyleElement> | [];
}

const validateDomain = () => {
  const domain = window.location.hostname;
  const allowedDomains = [COOKIE_DOMAIN, AGS_COOKIE_DOMAIN];
  const isDomainAllowed = allowedDomains.some(
    (allowedDomain) => domain.includes(allowedDomain),
  );

  if (!isDomainAllowed) {
    throw new Error(`Unknown domain: ${domain}; allowed domains: ${COOKIE_DOMAIN}, ${AGS_COOKIE_DOMAIN}`);
  }
};

export const getHeaderFooter = async (): Promise<HeaderFooter> => {
  try {
    validateDomain();
    const url = buildHeaderFooterUrl();
    const response: Response = await fetch(url);
    const text: string = await response.text();

    const parser = new DOMParser();
    const data: Document = parser.parseFromString(text, 'text/html');
    const headerData: Element | null = data.querySelector('div#wiley-shared-global-header');
    const footerData: Element | null = data.querySelector('div#wiley-shared-global-footer');
    const scripts: NodeListOf<HTMLScriptElement> = data.querySelectorAll('script');
    const styles: NodeListOf<HTMLStyleElement> = data.querySelectorAll('link[rel="stylesheet"]');

    if (!headerData || !footerData) {
      throw new Error('Header or Footer not found');
    }

    return {
      header: headerData.outerHTML,
      footer: footerData.outerHTML,
      scripts,
      styles,
    };
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    return {
      header: '',
      footer: '',
      scripts: [],
      styles: [],
    };
  }
};
