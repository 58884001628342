import { EditionFormat, ViaxOrderItem } from '@graphql/guest';

const TEXTBOOK_RENTAL_IDS = ['rental', 'Alta Rental'];

export const checkIsGuestAllowed = (products: ViaxOrderItem[]): boolean => {
  const hasRestrictedProducts = products.some(({ biiSalable }) => {
    const isDigital = biiSalable.wEditionFormat === EditionFormat.DIGITAL;
    const isPhysical = biiSalable.wEditionFormat === EditionFormat.PHYSICAL;
    const isPhygital = biiSalable.wEditionFormat === EditionFormat.DIGITAL_AND_PHYSICAL;
    const rentalCategory = biiSalable.maCategories.some((c) => TEXTBOOK_RENTAL_IDS.includes(c.catId));

    return isDigital || isPhygital || (isPhysical && rentalCategory);
  });

  return !hasRestrictedProducts;
};
