import { Turnstile } from '@services';
import { AdobeDataLayerEvent } from '@utils';

declare global {
  interface Window {
    onVerifyCaptcha: (token: string) => void;
    onloadTurnstileCallback: () => void;
    turnstile: Turnstile;
    adobeDataLayer: AdobeDataLayerEvent[];
  }
}

export type ChildrenProps = {
  children: React.ReactNode;
};

export enum HttpStatusCode {
  UNAUTHORIZED = '401',
  CONFLICT = '409',
  OK = '200',
  NOT_FOUND = '404',
  BAD_REQUEST = '400',
}
