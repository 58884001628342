import { useState } from 'react';
import { UseControllerProps } from 'react-hook-form';

import { cn } from '@utils';

import { ValidationInput } from '@components/ValidationInput';
import type { FormValues } from '@components/LoginForm/LoginForm';

import { ReactComponent as SpyIcon } from '@assets/images/spy.svg';
import { ReactComponent as UnSpyIcon } from '@assets/images/unspy.svg';
import './style.scss';

interface PasswordInputProps extends UseControllerProps<FormValues> {
  className?: string;
  label?: string;
  placeholder?: string;
  children?: React.ReactNode;
}

export const PasswordInput = (props: PasswordInputProps) => {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <div className={cn('password-input', [props.className || ''])}>
      <ValidationInput
        placeholder={props.placeholder}
        name={props.name}
        onIconClick={() => setIsVisible(!isVisible)}
        type={isVisible ? 'text' : 'password'}
        label={props.label}
        icon={isVisible ? <SpyIcon /> : <UnSpyIcon />}
      >
        {props.children}
      </ValidationInput>
    </div>
  );
};
