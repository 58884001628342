import { CommonInput } from '@components/common/CommonInput';
import { DisabledInputFunctions } from '@utils';
import { useController, UseControllerProps } from 'react-hook-form';

export interface ValidationInputProps extends UseControllerProps {
  label?: string;
  className?: string;
  onIconClick?: () => void;
  type?: 'text' | 'password';
  icon?: React.ReactNode;
  placeholder?: string;
  children?: React.ReactNode;
  disabledInputFunctions?: DisabledInputFunctions;
}

export const ValidationInput = (props: ValidationInputProps) => {
  const { field, fieldState } = useController(props);

  return (
    <CommonInput
      className={props.className}
      errorMessage={fieldState.error?.message}
      placeholder={props.placeholder}
      icon={props.icon}
      label={props.label}
      type={props.type}
      error={!!fieldState?.error?.message}
      onIconClick={props.onIconClick}
      onChange={field.onChange}
      onBlur={field.onBlur}
      value={field.value}
      disabledInputFunctions={props.disabledInputFunctions}
    >
      {props.children}
    </CommonInput>
  );
};
