import './style.scss';

import {
  useForm, FormProvider, Form,
} from 'react-hook-form';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSearchParams } from 'react-router-dom';

import { PAGE_ROUTES } from '@config/constants';
import {
  RegistrationValidationSchema,
  disabledInputFunctions,
  onRegistrationPageLoad,
  onRegistrationError,
  onSignInClick,
  useMount,
  onContinueAsGuestClick,
  SiteConfig,
} from '@utils';

import { CommonButton } from '@components/common/CommonButton';
import { PasswordInput } from '@components/PasswordInput';
import { CommonCheckbox } from '@components/common/CommonCheckbox';
import { CommonLink } from '@components/common/CommonLink';
import { ErrorSection } from '@components/ErrorSection';
import { ValidationInput } from '@components/ValidationInput';
import { TurnstileCaptcha } from '@components/TurnstileCaptcha';
import { PasswordRequirements } from '@components/PasswordRequirements';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { registrationAction, registrationSelector } from '@store/slices/registration';
import { guestSelector } from '@store/slices/guest/selectors';
import { loginGuest } from '@store/slices/guest/actions/loginGuest';
import { HttpStatusCode } from '@types';

export type FormValues = {
  email: string;
  confirmEmail: string;
  password: string;
  captchaToken: string;
};

export const RegistrationForm = () => {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const { locale, iso: country } = SiteConfig.getConfig();
  const { isLoading, error, errorCode } = useAppSelector(registrationSelector);
  const { isGuestAllowed, isGuestLoading, loginGuestError } = useAppSelector(guestSelector);
  const [optIn, setOptIn] = useState(false);
  const [isPlaceGuestOrderError, setIsPlaceGuestOrderError] = useState(false);

  const { t } = useTranslation();

  const methods = useForm<FormValues>({
    defaultValues: {
      email: '',
      confirmEmail: '',
      password: '',
      captchaToken: '',
    },
    mode: 'all',
    resolver: yupResolver(RegistrationValidationSchema()),
  });

  const onVerifyCaptcha = useCallback((token: string) => {
    methods.setValue('captchaToken', token);
    methods.clearErrors('captchaToken');
  }, [methods]);
  const onToggleOptIn = () => setOptIn((prev) => !prev);

  const registrationHandler = methods.handleSubmit(async (data) => {
    const redirectUrl = searchParams.get('target');
    dispatch(registrationAction({
      inputData: {
        email: data.email,
        password: data.password,
        captchaToken: data.captchaToken,
        country,
        optIn,
      },
      redirectUrl,
    }));
  });

  const signInPathname = PAGE_ROUTES.LOGIN.replace(':locale', locale);
  const signInUrl = `${signInPathname}${window.location.search}`;

  useEffect(() => {
    onRegistrationPageLoad(locale, country);
  }, [locale, country]);

  useMount(() => {
    const { siteId } = SiteConfig.getConfig();
    const errorFlagKey = `${siteId}.${locale}.show.registration.guest.error.message`;
    const showGuestError = localStorage.getItem(errorFlagKey);

    if (showGuestError) {
      setIsPlaceGuestOrderError(true);
      localStorage.removeItem(errorFlagKey);
    }
  });

  const adobeFormFieldsErrorhandler = () => {
    const errorKeys = Object.keys(methods.formState.errors) as Array<keyof FormValues>;
    const validationErrors = [] as Array<string>;
    if (errorKeys.length > 0) {
      errorKeys.forEach((key) => {
        const clientError = methods.formState.errors[key];
        if (clientError && clientError.message) {
          validationErrors.push(clientError.message);
        }
      });
      if (validationErrors.length > 0) {
        onRegistrationError(validationErrors.join(' | '));
      }
    }
  };

  const handleSignIn = () => {
    onSignInClick(t('registration.form.signIn'), signInUrl);
  };

  const handleRegistration = async () => {
    setIsPlaceGuestOrderError(false);
    await registrationHandler();
    adobeFormFieldsErrorhandler();
  };

  const handleGuestLogin = () => {
    onContinueAsGuestClick(t('guest.checkout'));
    setIsPlaceGuestOrderError(false);
    dispatch(loginGuest());
    adobeFormFieldsErrorhandler();
  };

  const guestErrorMsg = isPlaceGuestOrderError ? t('guest.placeOrderError') : t('guest.error');

  return (
    <div className="registration-form" data-testid="registration-form">
      <h2 className="registration-form__title">
        {t('registration.form.title')}
      </h2>
      <div className="registration-form__create-account text-subtitle-2">
        <span>
          {t('registration.form.alreadyHaveAccount')}
        </span>
        <CommonLink url={signInUrl} title={t('registration.form.signIn')} onClick={handleSignIn} />
      </div>

      {(error || loginGuestError || isPlaceGuestOrderError) && (
        <ErrorSection>
          {error ? t(`registration.form.validation.errors.codes.${errorCode}`) : guestErrorMsg}
          {errorCode === HttpStatusCode.CONFLICT && (
            <CommonLink type="warning" url={signInUrl} title={t('registration.form.signIn')} />
          )}
        </ErrorSection>
      )}

      <FormProvider {...methods} data-testid="registration-form">
        <Form className="registration-form__fields">
          <ValidationInput
            className="registration-form__fields--email"
            name="email"
            placeholder={t('registration.form.email.placeholder')}
            label={t('registration.form.email.label')}
            disabledInputFunctions={disabledInputFunctions}
          />
          <ValidationInput
            className="registration-form__fields--confirm-email"
            name="confirmEmail"
            placeholder={t('registration.form.confirmEmail.placeholder')}
            label={t('registration.form.confirmEmail.label')}
            disabledInputFunctions={disabledInputFunctions}
          />
          <PasswordInput
            label={t('registration.form.password.label')}
            placeholder={t('registration.form.password.placeholder')}
            className="registration-form__fields--password"
            name="password"
          >
            <PasswordRequirements field="password" />
          </PasswordInput>
          <TurnstileCaptcha action="registration" onVerifyCaptcha={onVerifyCaptcha} />
          {methods.formState.errors.captchaToken && (
            <p className="common-input__error-message text-subtitle-2">
              {t('registration.form.validation.errors.captchaRequire')}
            </p>
          )}
        </Form>
      </FormProvider>

      <div className="registration-form__password-actions text-subtitle-2">
        <CommonCheckbox onToggle={onToggleOptIn} checked={optIn} label={t('registration.form.emailsFromWiley')} />
      </div>

      <div className="registration-form__actions">
        <CommonButton
          type="primary"
          disabled={isLoading || isGuestLoading}
          loading={isLoading}
          onClick={handleRegistration}
        >
          {t('registration.form.registerAndCheckout')}
        </CommonButton>
        {isGuestAllowed && (
          <CommonButton
            type="secondary"
            disabled={isLoading || isGuestLoading}
            loading={isGuestLoading}
            onClick={handleGuestLogin}
          >
            {t('guest.checkout')}
          </CommonButton>
        )}
      </div>
    </div>
  );
};
