export interface Turnstile {
  execute: () => void;
  getResponse: (widgetId?: string) => string;
  implicitRender: (widgetId?: string) => void;
  isExpired: (widgetId?: string) => boolean;
  ready: (widgetId?: string) => void;
  remove: (widgetId?: string) => void;
  render: (
    elementId: string,
    config: {
      sitekey?: string,
      callback?: (token: string) => void,
      action: string;
    }
  ) => string;
  reset: (widgetId: string | null) => void;
}

export type TurnstileCaptchaAction = 'login' | 'registration';

interface WidgetRenderInput {
  elementId: string;
  config: {
    siteKey: string;
    action: TurnstileCaptchaAction;
    callback: (token: string) => void
  }
}

class TurnstileCaptcha {
  turnstile: Turnstile | null;

  widgetId: string | null = null;

  constructor() {
    this.turnstile = null;
  }

  setInstance(instance: Turnstile) {
    this.turnstile = instance;
  }

  reset() {
    if (!this.turnstile) return;

    this.turnstile.reset(this.widgetId);
    this.widgetId = null;
  }

  render({
    elementId,
    config: {
      siteKey, callback, action,
    },
  }: WidgetRenderInput) {
    if (!this.turnstile) return;

    const widgetId = this.turnstile.render(elementId, {
      sitekey: siteKey,
      callback,
      action,
    });

    this.setWidgetId(widgetId);
  }

  setWidgetId(id: string) {
    this.widgetId = id;
  }
}

export const TurnstileCaptchaService = new TurnstileCaptcha();
