import './style.scss';
import { cn } from '@utils';

interface CommonInputProps {
  className?: string;
  label?: string;
  onToggle: () => void;
  checked: boolean;
}

export const CommonCheckbox = (props: CommonInputProps) => {
  return (
    <div className={cn('checkbox-wrapper', [props.className || ''])}>
      <input
        name="checkbox-input"
        type="checkbox"
        checked={props.checked}
        onChange={props.onToggle}
      />
      <span className="checkmark">{props.label}</span>
    </div>
  );
};
