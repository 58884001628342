import { GraphQLClient } from 'graphql-request';
import { GRAPHQL_URI, PRICE_MODEL } from '@config/constants';
import { SiteConfig } from '@utils';

export const client = new GraphQLClient(GRAPHQL_URI!, {
  headers: () => ({
    'X-Viax-User-Locale': SiteConfig.getConfig().locale || '',
    'x-viax-user-pricing-model': PRICE_MODEL,
  }),
});
