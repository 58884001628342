import { ReactComponent as WarningIcon } from '@assets/images/warningIcon.svg';
import './style.scss';
import React from 'react';

type ErrorSectionProps = {
  children?: React.ReactNode;
};

const ErrorSection = ({ children }: ErrorSectionProps) => {
  return (
    <div data-testid="error-section" className="error-section">
      <WarningIcon />
      <p>
        { children}
      </p>
    </div>
  );
};

export { ErrorSection };
