import {
  useEffect, useLayoutEffect, useRef, useState,
} from 'react';

export const useMount = (cb: () => void = () => {}) => {
  const callbackRef = useRef(cb);

  useEffect(() => {
    callbackRef.current();
  }, []);
};

export const useUnmount = (cb: () => void = () => {}) => {
  const callbackRef = useRef(cb);
  callbackRef.current = cb;

  useEffect(() => {
    return () => {
      callbackRef.current();
    };
  }, []);
};

export const useWindowSize = () => {
  const [size, setSize] = useState<[number, number]>([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
};
