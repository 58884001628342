export const regLetter = '(?=(.*[a-z]))';
export const regCapital = '(?=(.*[A-Z]))';
export const regSpecial = '(?=(.*[!@#$%^&*()_\\+\\-{}[\\]\\\\\\|<>?\\/=:;"\']))';
export const regDigit = '(?=(.*[0-9]))';
export const regPass = new RegExp(
  // eslint-disable-next-line max-len
  `${regLetter}${regSpecial}${regDigit}|${regLetter}${regCapital}${regDigit}|${regLetter}${regCapital}${regSpecial}|${regCapital}${regSpecial}${regDigit}`,
);
// eslint-disable-next-line max-len
export const regEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
