import { gql } from 'graphql-request';

interface Error {
  code: string;
  message: string;
}

interface LoginGuestData {
  almToken: string;
  viaxToken: string;
}

export interface LoginGuestResponse {
  wEcomGuest: {
    result: {
      status: 'ERROR' | 'OK';
      data?: LoginGuestData;
      errors?: Error[];
    }
  }
}

export const LOGIN_GUEST_MUTATION = gql`
  mutation loginGuest($orderUid: String!) {
    wEcomGuest(orderUid: $orderUid)
  }
`;
