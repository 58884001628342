import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from '@api';
import { getCartId } from '@utils';
import { SERVER_ERROR } from '@config/constants';
import { LOGIN_MUTATION, LoginInput, LoginOutput } from '@graphql/login';

export const loginAction = createAsyncThunk<LoginOutput, LoginInput>(
  'login/loginAction',
  async ({ inputData, redirectUrl }, { rejectWithValue }) => {
    try {
      const orderUid = getCartId();
      const result = await client.request<LoginOutput>(LOGIN_MUTATION, { ...inputData, orderUid });
      return { ...result, redirectUrl };
    } catch (error) {
      return rejectWithValue(SERVER_ERROR);
    }
  },
);
